<template>
    <div class="aplayz_player_right_container">
        <div class="right_top">
            <h2 class="title_lg right_container_title">테마</h2>
        </div>

        <div class="right_parent_container">
            <div class="right_container1">
                <h2 class="title_md right_title">이 채널 어때요?</h2>
                <ul ref="verticalScrollWrap" class="popular_channel_list cursor">
                    <li
                        v-for="(item, index) in popularchannellist"
                        :id="`popular_${item.channelId}`"
                        :key="item.channelId"
                        class="popular_channel_item"
                        :class="{ first: index === 0, middle: index === 1 }"
                        @click="clickJoinChannel(item, 'popular')"
                    >
                        <figure class="popular_channel_img_wrap">
                            <div class="equalizer_wrap" v-if="isplay && item.channelId === $store.getters.gettersChannelId">
                                <equaliaer-comp />
                            </div>
                            <img :src="`${baseImgUrl}${item.imgUrl}`" :alt="`인기채널_${index + 1}`" class="popular_channel_img" />
                        </figure>
                        <p class="title_sm popular_channel_nm">{{ item.spaceNm }}</p>
                    </li>
                </ul>
            </div>
            <div class="right_container2">
                <h2 class="title_md right_title">
                    이런 채널도 있어요!
                    <span class="caption right_top_caption">{{ updatedate }} updated</span>
                    <div class="curation_info_img_wrap">
                        <img
                            id="curationInfoImg"
                            class="curation_info_img cursor"
                            src="/media/img/player/info.png"
                            alt="플레이리스트 1시간 업데이트 정보"
                            @click="curationInfoClick"
                            @mouseenter="curationInfoMouseEnter"
                            @mouseleave="curationInfoMouseLeave"
                        />
                    </div>
                </h2>
                <ul class="other_channel_list">
                    <li
                        class="other_channel_item cursor"
                        v-for="(item, idx) in otherchannellist"
                        :id="`design_${item.channelId}`"
                        :key="item.channelId"
                        @click="clickJoinChannel(item, 'design')"
                    >
                        <figure class="other_channel_img_wrap">
                            <div class="equalizer_wrap" v-if="isplay && item.channelId === $store.getters.gettersChannelId">
                                <equaliaer-comp />
                            </div>
                            <img class="other_channel_img" :src="`${baseImgUrl}${item.imgUrl}`" :alt="`추천채널_${idx + 1}`" />
                        </figure>
                        <div class="aplayz_channel_nm_wrap">
                            <h3 class="title_sm other_channel_nm">{{ item.spaceNm }}</h3>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
import { isNotPc } from '@/utils/Utils';
export default {
  name: 'MyPlayerRight',
  emits: ['joinchannel'],
  directives: { clickOutside: vClickOutside.directive },
  props: {
    popularchannellist: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    otherchannellist: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    updatedate: {
      type: String,
      required: false,
      default: ''
    },
    clickchannel: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    },
    isplay: {
      type: Boolean,
      required: false,
      default: false
    },
    activetab: {
      type: String,
      requried: false,
      default: ''
    }
  },
  components: {
    'equaliaer-comp': () => import(`@/components/player/Equalizer.vue`)
  },
  data () {
    return {
      baseImgUrl: process.env.VUE_APP_BASE_URL,
      isMouseDown: false,
      startX: 0,
      scrollLeft: 0,
      isOpenCurationInfo: false,
      isPc: !isNotPc(),
      resizeEvent: null,
      outsideClick: null
    };
  },

  mounted () {
    this.setSrcoll();
  },

  methods: {
    makeCurationInfo () {
      let curationInfoContents = document.querySelector('#curationinfocontents');
      const app = document.querySelector('.app');
      const curationInfoImg = document.querySelector('#curationInfoImg');

      if (!curationInfoContents) {
        const { top, left } = curationInfoImg.getBoundingClientRect();
        const { clientWidth } = curationInfoImg;
        curationInfoContents = document.createElement('p');
        curationInfoContents.id = 'curationinfocontents';
        curationInfoContents.classList.add('curation_info_contents');
        curationInfoContents.style.top = `${top + clientWidth + 10}px`;
        curationInfoContents.style.left = `${left}px`;
        curationInfoContents.style.zIndex = '99';
        curationInfoContents.innerHTML = `채널별 플레이리스트는 1시간마다 <br />
              고객님들의 피드백을 기반으로 업데이트됩니다.`;

        // 화살표 이미지
        const arrowTop = document.createElement('img');
        arrowTop.src = `/media/img/player/info_arrorw_top.png`;
        arrowTop.classList.add('curation_info_contents_arrowtop_img');

        curationInfoContents.appendChild(arrowTop);

        // 닫기 이미지
        this.isPc = !isNotPc();
        if (!this.isPc) {
          const closeImg = document.createElement('img');
          closeImg.src = `/media/img/player/info_close.png`;
          closeImg.classList.add('curation_info_contents_img');
          closeImg.classList.add('cursor');

          curationInfoContents.appendChild(closeImg);
        }

        app.appendChild(curationInfoContents);

        this.outsideClick = $event => {
          if ($event.target !== curationInfoContents && $event.target !== curationInfoImg) {
            app.removeChild(curationInfoContents);
            window.removeEventListener('click', this.outsideClick);
            window.removeEventListener('resize', this.resizeEvent);
            window.removeEventListener('scroll', this.resizeEvent);
          }
        };
        // resize
        this.resizeEvent = () => {
          const { top, left } = curationInfoImg.getBoundingClientRect();
          curationInfoContents.style.top = `${top + clientWidth + 10}px`;
          curationInfoContents.style.left = `${left}px`;
        };

        window.addEventListener('click', this.outsideClick);
        window.addEventListener('resize', this.resizeEvent);
        window.addEventListener('scroll', this.resizeEvent);
      }
    },
    deleteCurationInfo () {
      const curationInfoContents = document.querySelector('#curationinfocontents');
      const app = document.querySelector('.app');
      if (curationInfoContents) {
        app.removeChild(curationInfoContents);
        window.removeEventListener('click', this.outsideClick);
        window.removeEventListener('resize', this.resizeEvent);
      }
    },
    curationInfoMouseEnter () {
      if (this.isPc) {
        this.makeCurationInfo();
      }
    },
    curationInfoMouseLeave () {
      if (this.isPc) {
        this.deleteCurationInfo();
      }
    },
    curationInfoClick () {
      if (!this.isPc) {
        const curationInfoContents = document.querySelector('#curationinfocontents');
        if (curationInfoContents) {
          this.deleteCurationInfo();
        } else {
          this.makeCurationInfo();
        }
      }
    },
    clickJoinChannel (item, type) {
      this.clickchannel(item, type);
    },
    setSrcoll () {
      const verticalScrollWrap = this.$refs.verticalScrollWrap;
      verticalScrollWrap.addEventListener('mousedown', e => {
        this.isMouseDown = true;
        this.startX = e.pageX - verticalScrollWrap.offsetLeft;
        this.scrollLeft = verticalScrollWrap.scrollLeft;
      });
      verticalScrollWrap.addEventListener('mouseleave', () => {
        this.isMouseDown = false;
      });
      verticalScrollWrap.addEventListener('mouseup', () => {
        this.isMouseDown = false;
      });
      verticalScrollWrap.addEventListener('mousemove', e => {
        if (!this.isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - verticalScrollWrap.offsetLeft;
        const beforeScrollLeft = (x - this.startX) * 1;
        verticalScrollWrap.scrollLeft = this.scrollLeft - beforeScrollLeft;
      });
    }
  }
};
</script>
<style scoped src="@/assets/css/player/myplayerright.css"></style>
